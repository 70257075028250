import * as React from 'react';
// @ts-ignore
import { JobList } from 'career-page-components';
import './JobTable.css';
import arrow from '../../images/arrow.svg';

interface JobTableProps {
	data: any;
}
const JobTable: React.FC<JobTableProps> = (props: JobTableProps) => {
	const jobListOptions = {
		jobListClassNames: 'job-list',
		searchBarWrapperClassNames: 'search-bar-wrapper',
		searchBarClassNames: 'search-bar',
		jobResultsTitleWrapperClassNames: 'results-wrapper',
		jobResultsTitleClassNames: 'search-results',
		jobListWrapperClassNames: 'job-list-wrapper'
	};
	const applyItems = [
		{
			text: 'Category',
			value: 'category',
			hideLabel: false
		},
		{
			text: 'Req ID:',
			value: 'reqNumber',
			hideLabel: false
		}
	];
	return (
		<section className={'JobListSection'}>
			<div className={'bg-gp-green pt-8'}>
				<div className={'container mx-auto px-3'}>
					<a
						href={'/'}
						className={
							'flex max-w-[100px] flex-row font-lato text-white hover:text-white hover:underline'
						}
					>
						<img
							src={arrow}
							width={16}
							className={'mr-3 rotate-180'}
							alt={'Back Arrow.'}
						/>
						Back
					</a>
					<h1
						className={
							'mt-5 font-lato text-[40px] font-bold text-white'
						}
					>
						Job Listings
					</h1>
				</div>
			</div>
			<JobList
				data={props.data}
				options={jobListOptions}
				searchTargets={[
					'title',
					'city',
					'reqNumber',
					'category',
					'state'
				]}
				apiKey={'AIzaSyCHih204QmoSKcJhHZ2xCTWokZ2cKl1RXU'}
				applyNowValues={applyItems}
			/>
		</section>
	);
};

export default JobTable;
